import { Module } from 'vuex';
import { TGameState } from './types';
import { RootState } from '../types';
import { mutations } from './mutations';
import { actions } from './actions'; 

export const state: TGameState = {
    myMove: false,
    perks: [],
    currentGame: null,
    time: 30,
    counter: {
        player: 0,
        enemy: 0
    },
    points: {
        player: 0,
        enemy: 0
    },
    showResultGame: false,
    winner: false,
    technicalWinner: false,
    players: {},
    countPairBacklog: 0,
    combo: 0,
    emoji: { type: '', you: false },
};

export const game: Module<TGameState, RootState> = {
    state,
    mutations,
    actions
};