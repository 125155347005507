import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { MutationTypeError } from './mutations';
import { TErrorState, TResponseNotification } from './types';

export enum ActionsError {
    addError = 'ADD_ERROR',
    addInfo = 'ADD_INFO',
    removeNotifications = 'REMOVE_ERROR',
    addNotification = 'ADD_NOTIFICATION',
}

const MAX_NOTIFICATION = 3;
const DEFAULT_TIME_CLOSE = 3000;

export const actions: ActionTree<TErrorState, RootState> = {
    [ActionsError.addError]({ dispatch }, notifications: TResponseNotification[]) {
        dispatch(ActionsError.addNotification, { notifications, type: 'error' });        
    },
    [ActionsError.addInfo]({ dispatch }, notifications: TResponseNotification[]) {
        dispatch(ActionsError.addNotification, { notifications, type: 'info', options: { showClose: false } });        
    },
    [ActionsError.removeNotifications]({ commit }, idError) {
        commit(MutationTypeError.removeError, idError);
    },

    [ActionsError.addNotification]({ commit, state }, { notifications, type, options }) {
        notifications.forEach((notification: TResponseNotification) => {
            const id = Date.now();
            const newNotification = {
                id,
                type,
                field: notification?.params,
                options: notification?.options,
                message: notification.msg,
                showClose: options?.showClose || notification?.options?.showClose,
                avatar: notifications?.options?.avatar,
            };

            if (state.errors.length > MAX_NOTIFICATION) {
                commit(MutationTypeError.removeError, state.errors[state.errors.length - 1].id);
            }
            
            commit(MutationTypeError.addError, newNotification);
            
            if (options?.autoClose || notification?.options?.autoClose) {
                setTimeout(() => {
                    commit(MutationTypeError.removeError, id);
                }, DEFAULT_TIME_CLOSE);
            }
        });
    },
}
