
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Loader from '@/components/loader.vue';

@Options({
    components: {
        Loader,
    },
})
export default class BtnGame extends Vue {

    @Prop({ default: 'submit' })
    type!: 'submit' | 'button';

    @Prop({ default: false })
    disabled!: boolean;

    @Prop()
    text!: string;

    @Prop({ default: false })
    loading!: boolean;

    @Prop({ default: 'large' })
    size?: string;

    @Prop({ default: 'default' })
    customType?: string;

    @Prop()
    icon?: string;

    get isLoading() {
        return this.$store.state.common.isLoading;
    }

}
