import { Module } from 'vuex';
import { RootState } from '../types';
import { TUserState } from './types';
import { mutations } from './mutations';
import { actions } from './actions'; 

export const state: TUserState = {
    user: null,
    isAuth: false,
    friends: [],
    inviteFriend: null,
    inviteGames: [],
    inviteCodeGame: false,
};

export const user: Module<TUserState, RootState> = {
    state,
    mutations,
    actions
};