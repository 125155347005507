export enum Scenes {
    start = 'Start',
    selectMode = 'SelectMode',
    enemyTimer = 'EnemyTimer',
    game = 'Game',
    codeGame = 'CodeGame',
};

export interface TMode {
    title: string;
    count: number;
    active: boolean;
}

export interface GameInstanse extends Phaser.Game {
    client?: any | null;
}