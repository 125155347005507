import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { TUserState } from './types';
import AuthSetvice from '@/services/AuthService';
import { MutationUserType } from './mutations';
import { ActionsError } from '../errors/actions';
import { TOKEN } from '@/constants';
import { IFriendVk, IUser } from '@/model/User';
import UserSetvice from '@/services/UserService';
import { Friend } from '@/dtos/Friend';
import { ActionCommonTypes } from '../common/actions';

export enum ActionsUser {
    login = 'LOGIN',
    registration = 'REGISTRATION',
    refreshToken = 'REFRESH_TOKEN',
    logout = 'LOGOUT',
    checkAuth = 'CHECK_AUTH',
    setUser = 'SET_USER',
    addLife = 'ADD_LIFE',
    authProvider = 'AUTH_PROVIDER',
    friendsScore = 'FRIENDS_SCORE',
    setUnlimitedLifes = 'SET_UNLIMITED_LIFES',
    setInviteFriend = 'SET_INVITE_FRIEND',
    setInviteCodeGame = 'SET_INVITE_CODE_GAME',
    userInfo = 'GET_USER',
}

export const actions: ActionTree<TUserState, RootState> = {
    async [ActionsUser.registration]({ commit, dispatch }, { email, password, name }) {
        try {
            const response = await AuthSetvice.registration(email, password, name);
    
            localStorage.setItem(TOKEN, response.data.accessToken);
            commit(MutationUserType.setUser, response.data.user);
            commit(MutationUserType.setAuth, true);
        } catch (err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
            throw new Error;
        }
    }, 

    async [ActionsUser.userInfo]({ commit, dispatch }) {
        try {
            const response = await UserSetvice.getUser();
            commit(MutationUserType.setUser, response.data.user);
            commit(MutationUserType.setAuth, true);
        } catch (err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
            throw new Error;
        }
    },

    async [ActionsUser.login]({ commit, dispatch }, { email, password }) {
        try {
            const response = await AuthSetvice.login(email, password);
            localStorage.setItem(TOKEN, response.data.accessToken);
            commit(MutationUserType.setUser, response.data.user);
            commit(MutationUserType.setAuth, true);
        } catch (err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
            throw new Error;
        }
    },

    async [ActionsUser.logout]({ commit, dispatch }) {
        try {
            await AuthSetvice.logout();           
            localStorage.setItem(TOKEN, '');
            commit(MutationUserType.setUser, {} as IUser);
            commit(MutationUserType.setAuth, false);
        } catch (err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
        }
    },

    async [ActionsUser.refreshToken]({ commit, dispatch }) {
        try {
            const response = await AuthSetvice.checkAuth();
            localStorage.setItem(TOKEN, response.data.accessToken);
            commit(MutationUserType.setUser, response.data.user);
            commit(MutationUserType.setAuth, true);
        } catch (err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
        }
    },

    [ActionsUser.setUser]({ commit }, user: IUser) {
        commit(MutationUserType.setUser, user);
    },

    async [ActionsUser.addLife]({ commit, dispatch }, userid) {
        try {
            const response = await UserSetvice.addLifeUser(userid);
            commit(MutationUserType.setUser, response.data?.user);
        } catch(err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
        }
    },

    async [ActionsUser.authProvider]({ commit, dispatch, }, { provider, id, name, avatar }) {
        try {
            const response = await AuthSetvice.authProvider(provider, id, name);
            localStorage.setItem(TOKEN, response.data.accessToken);
            commit(MutationUserType.setUser, {...response.data.user, avatar });
            commit(MutationUserType.setAuth, true);
            
            if (response.data.inviteGames.length) {
                response.data.inviteGames.forEach(invite => {
                    dispatch(ActionsError.addInfo, [{
                        msg: 'Вам приглашение в игру от:',
                        options: {
                            invite: true,
                            name: invite.player.name,
                            avatar: invite.player.avatar,
                            gameId: invite.id,
                        }
                    }]);
                });
            }
            
            if (response.data.firstVisit) {
                setTimeout(() => {
                    dispatch(ActionCommonTypes.showRules, true);
                }, 2000);   
            }
        } catch(err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
        }
    },

    async [ActionsUser.friendsScore]({ commit, dispatch }, users) {
        try {
            const friendsIds = users.map((user: { id: number; }) => user.id);
            const { data } = await UserSetvice.getScoreUsersById(friendsIds);
            
            let friendsScore = data.users.reduce((acc: Record<string, { points: number, id: string }>, friend) => {
                if (friend.providerId) {
                    acc[friend.providerId] = { points: friend.points || 0, id: friend._id || '' };
                }
                return acc;
            }, {});
            
            const friends = users.filter((user: IFriendVk) => friendsScore[user.id]).map((user: IFriendVk) => {
                return {
                    ...new Friend(user),
                    score: friendsScore[user.id].points || 0,
                    id: friendsScore[user.id].id,
                };
            });
            
            commit(MutationUserType.setFriends, friends);
        } catch(err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
        }
    },

    async [ActionsUser.setUnlimitedLifes]({ commit, dispatch, state }, userid) {
        try {
            const response = await UserSetvice.setUnlimitedLifes(userid);
            commit(MutationUserType.setUser, { ...state.user, ...response.data?.user });
        } catch(err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
        }
    },

    [ActionsUser.setInviteFriend]({ commit }, friend) {
        commit(MutationUserType.setInviteFriend, friend);
    },

    [ActionsUser.setInviteCodeGame]({ commit }, game) {
        commit(MutationUserType.setInviteCodeGame, game);
    },
}
