import $api from '../http';
import { AxiosResponse } from 'axios';
import { IUser, CheckGameForUser } from '@/model/User';
import { URLS } from '@/constants';

export default class UserSetvice {
    static async fetchUsers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>(URLS.api.users);
    }

    static async addLifeUser(userid: string): Promise<AxiosResponse<{ user: IUser }>> {
        return $api.post(URLS.api.addLife, { userid });
    }

    static async setUnlimitedLifes(userid: string): Promise<AxiosResponse<{ user: IUser }>> {
        return $api.post(URLS.api.setUnlimitedLifes, { userid });
    }

    static async checkGameForUser(userid: string): Promise<AxiosResponse<CheckGameForUser>> {
        return $api.post(URLS.api.checkGame, { userid });
    }

    static async getTopUsers(page: number, limit: number): Promise<AxiosResponse<IUser[]>>  {
        return $api.get<IUser[]>(URLS.api.topusers, { params: { page, limit } });
    }

    static async getScoreUsersById(users: string[]): Promise<AxiosResponse<{ users: IUser[] }>> {
        return $api.post(URLS.api.usersScore, { users });
    }

    static async getUser(): Promise<AxiosResponse<{ user: IUser }>> {
        return $api.get(URLS.api.user);
    }
}  
