import { MutationTree } from 'vuex';
import { TErrorState, TError } from './types';

export enum MutationTypeError {
    addError = 'ADD_ERROR',
    removeError = 'REMOVE_ERROR',
}

export type Mutations = {
    [MutationTypeError.addError](state: TErrorState, error: TError): void,
    [MutationTypeError.removeError](state: TErrorState, id: string): void,
}

export const mutations: MutationTree<TErrorState> & Mutations= {
    [MutationTypeError.addError](state, error) {
        state.errors.push(error);
    },
    [MutationTypeError.removeError](state, id) {
        state.errors = state.errors.filter(error => error.id !== id);
    },
};
