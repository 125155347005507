export const validEmail = (email: string): boolean => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const randomInteger = (quantity: number, max: number) => {
    const set = new Set();
    while(set.size < quantity) {
      set.add(Math.floor(Math.random() * max) + 1);
    }

    return set;
}
