<template>
    <div class="authPage">
        <div class="authLogo">
            <img src="@/assets/img/logo.png" alt="paraClash">
        </div>
        <auth-form @sendAuthForm="registration" type="registration" />
        <router-link class="authLink" to="/login">{{ $t('auth.login_btn') }}</router-link>
    </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { ActionsUser } from '@/store/user/actions';
import { ActionCommonTypes } from '@/store/common/actions';
import AuthForm from '@/components/authForm.vue';

@Options({
  components: {
      AuthForm,
  },
})
export default class LoginPage extends Vue {

    get isLoading() {
        return this.$store.state.common.isLoading;
    }

   async registration ({ email, password, name }: { email: string, password: string, name: string }): Promise<void> {
        if (this.isLoading) {
            return;
        } 

        this.$store.dispatch(ActionCommonTypes.setLoading, true);

        try {
            await this.$store.dispatch(ActionsUser.registration, { email, password, name });
            this.$router.push({ name: 'game' });
            this.$store.dispatch(ActionCommonTypes.setLoading, false);
        } catch (err) {
            this.$store.dispatch(ActionCommonTypes.setLoading, false);
            console.log(err);
        }    
    }
}
</script>
<style lang="sass" src="@/styles/authForm.sass"></style>
