import { Module } from 'vuex';
import { Scenes } from '@/model/Common';
import { TCommonState } from './types';
import { RootState } from '../types';
import { mutations } from './mutations';
import { actions } from './actions'; 

export const state: TCommonState = {
    currentScene: Scenes.start,
    gameModeList: [],
    selectedMode: 0,
    isLoading: false,
    showModalGame: false,
    showRules: false,
    soundOn: false,
    showModalAddLife: false,
    showModalExitGame: false,
    changeGame: '',
    provider: '',
};

// const namespaced: boolean = true;

export const common: Module<TCommonState, RootState> = {
    //namespaced,
    state,
    mutations,
    actions
};