import { ActionTree } from 'vuex'
import { RootState } from "../types";
import { MutationType } from './mutations';
import { TGame } from '@/model/Game';
import { TGameState } from './types';
import { Scenes } from '@/model/Common';
import { ActionCommonTypes } from '@/store/common/actions';
import { ActionsError } from '../errors/actions';
import UserSetvice from '@/services/UserService';

export enum ActionsGames {
    setMyMove = 'SET_MY_MOVE',
    setPoints = 'SET_POINTS',
    gameStart = 'SOCKET_gameStart',
    timerTick = 'TIMER_TICK',
    setCounter = 'SET_COUNTER',
    showResultGame = 'SHOW_RESULT_GAME',
    setWinner = 'SET_WINNER',
    setTechnicalWinner = 'SET_TECHNICAL_WINNER',
    setGameId = 'SET_GAME_ID',
    setCountPairBacklog = 'SET_COUNT_PAIR_BACKLOG',
    checkGame = 'CHECK_GAME',
    setCombo = 'SET_COMBO',
    sendEmoji = 'SOCKET_sendEmoji',
}

export const actions: ActionTree<TGameState, RootState> = {
    [ActionsGames.setMyMove]({ commit }, move: boolean) {
        commit(MutationType.setMyMove, move);
    },
    [ActionsGames.setPoints]({ commit }, points: { player: number, enemy: number }) {
        commit(MutationType.setPoints, points);
    },
    [ActionsGames.gameStart]({ commit, dispatch }, game: TGame) {
        commit(MutationType.setGame, game);
        if (game) {
            const perks = game.perks.map(perk => ({ ...perk, countPerkUse: game.countPerkUse }));
            commit(MutationType.setPerks, perks);
            commit(MutationType.setPlayers, { player: game.playerInfo, enemy: game.enemyInfo});
            commit(MutationType.setCountPairBacklog, game.countPairBacklog);
            dispatch(ActionCommonTypes.setScene, Scenes.game);
        }
    },
    [ActionsGames.timerTick]({ commit }, time: number) {
        commit(MutationType.setTime, time);
    },
    [ActionsGames.setCounter]({ commit }, counter: { player: number, enemy: number }) {
        commit(MutationType.setCounter, counter);
    },
    [ActionsGames.showResultGame]({ commit }, show: boolean) {
        commit(MutationType.setShowResultGame, show);
    },
    [ActionsGames.setWinner]({ commit }, win: boolean) {
        commit(MutationType.setWinner, win);
    },
    [ActionsGames.setTechnicalWinner]({ commit }, disconect: boolean) {
        commit(MutationType.setTechnicalWinner, disconect);
    },
    [ActionsGames.setGameId]({ commit }, game: TGame) {
        commit(MutationType.setGame, game);
    },
    [ActionsGames.setCountPairBacklog]({ commit }, count: number) {
        commit(MutationType.setCountPairBacklog, count);
    },
    [ActionsGames.setCombo]({ commit }, combo: number) {
        commit(MutationType.setCombo, combo);
    },
    async [ActionsGames.checkGame]({ dispatch }, userId: string) {
        try {
            const { data: { checkGame } } = await UserSetvice.checkGameForUser(userId);
            return checkGame;
        } catch (err: any) {
            dispatch(ActionsError.addError, err.response.data.errors);
        }
    },
    [ActionsGames.sendEmoji]({ commit }, emoji: string | { type: string, you: boolean} ) {
        if (typeof emoji === 'string') {
            commit(MutationType.setEmoji, { type: emoji, you: false });
        } else {
            commit(MutationType.setEmoji, emoji);
        }
    },
} 
