import { createApp } from 'vue'
import router from './router/';
import App from './App.vue'
import store from './store';

import io from 'socket.io-client';
import VueSocketIO from 'vue-3-socket.io';

import { createI18n } from 'vue-i18n';
import { languages, defaultLocale } from './i18n';

const i18n = createI18n({
    locale: defaultLocale,
    legacy: true,
    fallbackLocale: 'ru',
    messages: Object.assign(languages),
});

const SOCKET_HOST = process.env.VUE_APP_SOCKET_URL;

export const SocketInstance = io(SOCKET_HOST, { transports: ['websocket', 'polling', 'flashsocket'] });

const app = createApp(App);

app.use(new VueSocketIO({
    connection: SocketInstance,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
    },
}));
app.use(i18n);
app.use(store);
app.use(router);

app.mount('#app');
