import { MutationTree } from "vuex";
import { TGameState, TGamePlayers } from './types';
import { TGame, TPerk } from '@/model/Game';

export enum MutationType {
    setMyMove = 'SET_MY_MOVE',
    setPoints = 'SET_POINTS',
    setPerks = 'SET_PERKS',
    setGame = 'SET_GAME',
    setTime = 'SET_TIME',
    setCounter = 'SET_COUNTER',
    setShowResultGame = 'SET_SHOW_RESULT_GAME',
    setWinner = 'SET_WINNER',
    setTechnicalWinner = 'SET_TECHNICAL_WINNER',
    setPlayers = 'SET_PLAYERS',
    setCountPairBacklog = 'SET_COUNT_PAIR_BACKLOG',
    setCombo = 'SET_COMBO',
    setEmoji = 'SET_EMOJI',
}

export type Mutations = {
    [MutationType.setMyMove](state: TGameState, move: boolean): void,
    [MutationType.setPoints](state: TGameState, points: { player: number, enemy: number }): void,
    [MutationType.setPerks](state: TGameState, perks: Array<TPerk>): void,
    [MutationType.setGame](state: TGameState, game: TGame): void,
    [MutationType.setTime](state: TGameState, time: number): void,
    [MutationType.setCounter](state: TGameState, counter: { player: number, enemy: number }): void,
    [MutationType.setShowResultGame](state: TGameState, show: boolean): void,
    [MutationType.setWinner](state: TGameState, win: boolean): void,
    [MutationType.setTechnicalWinner](state: TGameState, disconnect: boolean): void,
    [MutationType.setPlayers](state: TGameState, players: TGamePlayers): void,
    [MutationType.setCountPairBacklog](state: TGameState, count: number): void,
    [MutationType.setCombo](state: TGameState, combo: number): void,
    [MutationType.setEmoji](state: TGameState, emoji: { type: string, you: boolean }): void,
}

export const mutations: MutationTree<TGameState> & Mutations= {
    [MutationType.setMyMove](state, move) {
        state.myMove = move;
    },
    [MutationType.setPoints](state, points) {
        state.points = points;
    },
    [MutationType.setPerks](state, perks) {
        state.perks = perks;
    },
    [MutationType.setGame](state, game) {
        state.currentGame = game;
    },
    [MutationType.setTime](state, time) {
        state.time = time;
    },
    [MutationType.setCounter](state, counter) {
        state.counter = counter;
    },
    [MutationType.setShowResultGame](state, show) {
        state.showResultGame = show;
    },
    [MutationType.setWinner](state, win) {
        state.winner = win;
    },
    [MutationType.setTechnicalWinner](state, disconnect) {
        state.technicalWinner = disconnect;
    },
    [MutationType.setPlayers](state, players) {
        state.players = players;
    },
    [MutationType.setCountPairBacklog](state, count) {
        state.countPairBacklog = count;
    },
    [MutationType.setCombo](state, combo) {
        state.combo = combo;
    },
    [MutationType.setEmoji](state, emoji) {
        state.emoji = emoji;
    },
}
