import { MutationTree } from 'vuex';
import { TUserState } from './types';
import { inviteCodeGame, IInviteGame, IUser, IUserFriend } from '@/model/User';

export enum MutationUserType {
    setUser = 'SET_USER',
    setAuth = 'SET_AUTH',
    setFriends = 'SET_FRIENDS',
    setInviteFriend = 'SET_INVITE_FRIEND',
    setInviteGames = 'SET_INVITE_GAMES',
    setInviteCodeGame = 'SET_INVITE_CODE_GAME',
}

export type Mutations = {
    [MutationUserType.setUser](state: TUserState, user: IUser): void;
    [MutationUserType.setAuth](state: TUserState, auth: boolean): void;
    [MutationUserType.setFriends](state: TUserState, friends: IUserFriend[]): void;
    [MutationUserType.setInviteFriend](state: TUserState, friend: IUserFriend): void;
    [MutationUserType.setInviteGames](state: TUserState, games: IInviteGame[]): void;
    [MutationUserType.setInviteCodeGame](state: TUserState, game: boolean): void;
}

export const mutations: MutationTree<TUserState> & Mutations = {
    [MutationUserType.setUser](state, user) {
        state.user = { ...state.user, ...user };
    },
    [MutationUserType.setAuth](state, auth) {
        state.isAuth = auth;
    },
    [MutationUserType.setFriends](state, friends) {
        state.friends = friends;
    },
    [MutationUserType.setInviteFriend](state, friend) {
        state.inviteFriend = friend;
    },
    [MutationUserType.setInviteGames](state, games) {
        state.inviteGames = games;
    },
    [MutationUserType.setInviteCodeGame](state, game) {
        state.inviteCodeGame = game;
    },
}