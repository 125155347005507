<template>
    <form @submit.prevent="$emit('sendAuthForm', { email, password, name })" class="authForm">
        <div v-if="type === AUTH_FORM_TYPE.reg" class="authField">
            <input
                @change="onChangeHandled"
                v-model="name"
                :class="{error: errors.name}"
                type="text"
                name="name"
                :placeholder="$t('auth.name')"
            >
        </div>
        <div class="authField">
            <input
                @change="onChangeHandled"
                v-model="email"
                :class="{error: errors.email}"
                type="text"
                name="email"
                placeholder="E-mail"
            >
        </div>
        <div class="authField">
            <input
                @change="onChangeHandled"
                v-model="password"
                :class="{error: errors.password}"
                type="password"
                name="password"
                :placeholder="$t('auth.password')"
            >
        </div>
        <BtnGame :disabled="btnDisabled" :text="btnText" :loading="isLoading" />
    </form>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MIN_PASSWORD_LENGTH, AUTH_FORM_TYPE } from '@/constants';
import { validEmail } from '@/utils/index';
import BtnGame from '@/components/btnGame.vue';

@Options({
    components: {
        BtnGame,
    },
})
export default class AuthForm extends Vue {
    email = '';
    password = '';
    name = '';
    errors = {
        email: false,
        password: false,
        name: false,
    };
    AUTH_FORM_TYPE = AUTH_FORM_TYPE;

    @Prop({ default: AUTH_FORM_TYPE.login })
    type!: string;

    get btnText() {
        return this.type === AUTH_FORM_TYPE.login ? this.$t('auth.login_btn') : this.$t('auth.registration_btn');
    }

    get btnDisabled() {
        return !this.email || this.password.length < MIN_PASSWORD_LENGTH || !validEmail(this.email) || (this.type === AUTH_FORM_TYPE.reg && !this.name);
    }

     get isLoading() {
        return this.$store.state.common.isLoading;
    }

    onChangeHandled(e: Event) {
        if (!validEmail(this.email) && this.email.length > 0) {
            this.errors.email = true;
        } else {
            this.errors.email = false;
        }

        if (this.password.length < MIN_PASSWORD_LENGTH && this.password.length > 0) {
            this.errors.password = true;
        } else {
            this.errors.password = false;
        }
    }
}
</script>
