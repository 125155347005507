import $api from '../http';
import { AxiosResponse } from 'axios';
import { AuthResponse } from '@/model/User';
import { URLS } from '@/constants';

export default class AuthSetvice {
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>(URLS.api.login, { email, password });
    }

    static async registration(email: string, password: string, name: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>(URLS.api.registration, { email, password, name });
    }

    static async logout(): Promise<void> {
        return $api.post(URLS.api.logout);
    }

    static async checkAuth(): Promise<AxiosResponse<AuthResponse>> {
        return $api.get(URLS.api.refresh);
    }

    static async authProvider(provider: string, id: string, name: string): Promise<AxiosResponse<AuthResponse>>  {
        return $api.post(URLS.api.authProvider, { provider, id, name });
    }
}  
