
import { Options, Vue } from 'vue-class-component';
import { ActionsError } from '@/store/errors/actions';
import InviteFriend from './InviteFriend.vue';
import { TResponseNotification } from '@/store/errors/types';

@Options({
    components: {
        InviteFriend,
    },
})
export default class Notification extends Vue {
    get notifications() {
        return this.$store.state.errors.errors;
    }

    showInvite(error: TResponseNotification) {
        return error?.options?.invite;
    }

    closeNotification(idError: string) {
        this.$store.dispatch(ActionsError.removeNotifications, idError);
    }
}
