import axios, {AxiosRequestConfig} from 'axios';
import { TOKEN, URLS, HTTP_CODES } from '@/constants';

export const API_URL = process.env.VUE_APP_API_URL;

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

$api.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    config = { ...config };

    if (localStorage.getItem(TOKEN)) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
        }
    }
    
    return config;
});

$api.interceptors.response.use((config) => config, async (error) => {
    const originalRequest = error.config;
    if (error.response.status == HTTP_CODES.Unauthorized && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${API_URL}${URLS.api.refresh}`, { withCredentials: true })
            localStorage.setItem(TOKEN, response.data.accessToken);
            return $api.request(originalRequest);
        } catch (e) {
            localStorage.removeItem(TOKEN);
            console.log(error);
        }
    }
    throw error;
})

export default $api;
