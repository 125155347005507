import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { common } from './common/';
import { game } from './game/';
import { user } from './user';
import { errors } from './errors';

const store: StoreOptions<RootState> = {
  state: {
    version: '1.3.0',
    modeTest: false,
  },
  modules: {
    common,
    game,
    user,
    errors,
  },
};

export default new Vuex.Store<RootState>(store);