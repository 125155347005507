<template>
    <div v-if="inviteStatus === inviteStatuses.pending" class="invite-friend">
        <div class="invite-info">
            <div class="invite-info_avatar">
                <img :src="avatar" :alt="name" />
            </div>
            <span>{{ name }}</span>
        </div>
        <div class="invite-friend_actions">
            <BtnGame @click="acceptInvite" :text="$t('accept')" size="medium" />
            <BtnGame @click="rejectInvite" :text="$t('reject')" size="medium" />
        </div>
    </div>
    <div v-if="inviteStatus === inviteStatuses.reject" class="invite-friend">
        <div class="invite-info">
            <div class="invite-info_text">
                {{ $t('friend_invite_canceled') }}
            </div>
        </div>
        <div class="invite-friend_actions">
            <BtnGame @click="$emit('close')" :text="$t('close')" size="medium" />
        </div>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BtnGame from '@/components/btnGame.vue';
import { INVITE_STATUS, PROVIDERS } from '@/constants';
import { ActionCommonTypes } from '@/store/common/actions';

@Options({
    components: {
        BtnGame,
    },
    sockets: {
        inviteCanceled() {
            this.inviteStatus = INVITE_STATUS.reject
        },
        inviteAccepted() {
            this.$emit('close');
        }
    },
})
export default class InviteFriend extends Vue {
    $socket: any;
    inviteStatus = INVITE_STATUS.pending;
    inviteStatuses = INVITE_STATUS;

    @Prop()
    name!: string;

    @Prop()
    avatar!: string;

    @Prop()
    gameid!: string;

    get isVk() {
        return this.$store.state.common.provider === PROVIDERS.vk;
    }

    acceptInvite() {
        if (!this.$store.state.game.currentGame) {
            if ((this.isVk && this.$store.state.user.user.lifes > 0) || (!this.isVk && this.$store.state.user.user.lifes === 3)) {
                this.$socket.emit('joinGame', { gameId: this.gameid, user: this.$store.state.user.user });
            } else {
                this.$store.dispatch(ActionCommonTypes.showModal, true);
            }
        } else {
            this.$store.dispatch(ActionCommonTypes.setShowExitGame, true);
            this.$store.dispatch(ActionCommonTypes.setChangeGame, this.gameid);
        }
    }

    rejectInvite() {
        this.$socket.emit('rejectInvite', { gameId: this.gameid });
        this.$emit('close');
    }
}
</script>
<style lang="sass" scoped src="@/styles/inviteFriend.sass"></style>
