export const TOKEN = 'auth_token';

export const HTTP_CODES = {
    Unauthorized: 401,
};

export const MIN_PASSWORD_LENGTH = 6;

export const COUNT_LIFES = 3;

export const URLS = {
    login: '/login',
    registration: '/registration',
    logout: '/logout',
    game: '/',
    games: '/games',
    api: {
        login: '/login',
        registration: '/registration',
        authProvider: '/authProvider',
        logout: '/logout',
        refresh: '/refresh',
        users: '/users',        
        topusers: '/topusers',
        addLife: '/addLife',
        checkGame: '/checkGame',
        usersScore: '/getScore',
        setUnlimitedLifes: '/setunlimited',
        user: '/user',
        games: (date: string) => `/games/${date}`,
        gameByCode: (code: string) => `/game/${code}`,
    },
};

export const AUTH_FORM_TYPE = {
    login: 'login',
    reg: 'registration',
};

export const CLOUDS = [
    {
        top: 2,
        speed: 160,
        size: 'large',
    },
    {
        top: 30,
        speed: 90,
        delay: 10,
        startx: 90,
    },
    {
        top: 2,
        speed: 150,
        size: 'small',
        over: true,
        delay: 20,
        startx: 50,
    },
    {
        top: 50,
        speed: 180,
        delay: 2,
        size: 'small',
    },
    {
        top: 10,
        speed: 180,
        delay: 3,
    },
    {
        top: 35,
        speed: 200,
        size: 'small',
        startx: 70,
    },
    {
        top: 60,
        speed: 200,
        size: 'large',
        startx: 20,
    },
    {
        top: 60,
        speed: 180,
        startx: 90,
        delay: 10,
    },
    {
        top: 60,
        speed: 180,
        size: 'small',
        startx: 90,
    },
];

export const PERK_RANDOM_CARD_COUNT: Record<number, number> = {
    16: 4,
    36: 6,
    64: 8,
};

export const PERK_RANDOM_CARD_MIN_COUNT_OPENED: Record<number, number> = {
    16: 8,
    36: 12,
    64: 12,
};

export const PERK_ID = {
    MIX: 'shafl',
    RANDOM_CARDS: 'randomcards',
    OKO: 'fogofwar',
    IS_MINE: 'secondchance',
    TWO_STEP: 'twoversusone',
    DOUBLE_POINTS: 'doublepoints',
    VA_BANK: 'bet',
};

export const INVITE_STATUS = {
    pending: 'pending',
    reject: 'reject',
};

export const MAX_IDEAL_STEPS_COUNT = 5;
export const MIN_IDEAL_STEPS_COUNT = 1;

export const PROVIDERS = {
    vk: 'vk',
    ya: 'ya',
    cg: 'cg',
};

export const SDK_URL = {
    ya: 'https://yandex.ru/games/sdk/v2',
    cg: 'https://sdk.crazygames.com/crazygames-sdk-v1.js',
};
