
import { Vue, Options } from 'vue-class-component';
import { ActionsUser } from '@/store/user/actions';
import { ActionCommonTypes } from '@/store/common/actions';
import AuthForm from '@/components/authForm.vue';

@Options({
    components: {
        AuthForm,
    },
})
export default class LoginPage extends Vue {
    email = '';
    password = '';

    get isLoading() {
        return this.$store.state.common.isLoading;
    }

   async login ({ email, password }: { email: string, password: string }): Promise<void> {
        if (this.isLoading) {
            return;
        }       

        this.$store.dispatch(ActionCommonTypes.setLoading, true);
        
        try {
            await this.$store.dispatch(ActionsUser.login, { email, password });
            this.$router.push({ name: 'game' });
            this.$store.dispatch(ActionCommonTypes.setLoading, false);
        } catch (err) {
            this.$store.dispatch(ActionCommonTypes.setLoading, false);
            console.log(err);
        } 
    }
}
