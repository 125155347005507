import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import Login from '@/pages/login.vue';
import Registration from '@/pages/registration.vue';
import store from '@/store/';
import { TOKEN, URLS } from '@/constants'
import { ActionsUser } from '@/store/user/actions';
import bridge from '@vkontakte/vk-bridge';

const AuthPageGuard = async (from: RouteLocationNormalized, to: RouteLocationNormalized, next: NavigationGuardNext) => {
    // @ts-ignore
    if (localStorage.getItem(TOKEN) && typeof YaGames === 'undefined' &&
        !bridge.isIframe() &&
        !bridge.isEmbedded() &&       
        typeof window.CrazyGames === 'undefined'
    ) {
        await store.dispatch(ActionsUser.refreshToken);
        store.state.user.isAuth ? next(URLS.game) : next();
    } else {
        next();
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: URLS.login,
            name: 'login',
            component: Login,
            beforeEnter: AuthPageGuard,
        },
        {
            path: URLS.registration,
            name: 'registration',
            component: Registration,
            beforeEnter: AuthPageGuard,
        },
        {
            path: URLS.game,
            name: 'game',
            meta: { auth: true },
            component: () => import(/* webpackChunkName: "game" */ '@/pages/game.vue'),
        },
        {
            path: URLS.games,
            name: 'games',
            meta: { auth: true },
            component: () => import(/* webpackChunkName: "games" */ '@/pages/games.vue'),
        },
    ],
});

// @ts-ignore
if (!bridge.isIframe() && !bridge.isEmbedded() && typeof YaGames === 'undefined' && typeof window.CrazyGames === 'undefined') {
    router.beforeEach(async (to, from, next) => {
        if (to.meta.auth && !store.state.user.isAuth && localStorage.getItem(TOKEN)) {
            await store.dispatch(ActionsUser.refreshToken);
        }
    
        if (to.meta.auth && !store.state.user.isAuth) {
            next(URLS.login);
        } else {
            next();
        }
    });
}

export default router;
