import { IFriendVk } from "@/model/User";

export class Friend {
    idProvider: number;
    first_name: string;
    last_name: string;
    avatar: string;
    score: number;

    constructor(user: IFriendVk) {
        this.idProvider = user.id;
        this.first_name = user.first_name;
        this.last_name = user.last_name;
        this.avatar = user.photo_200;
        this.score = 0;
    }
}