<template>
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</template>

<style lang="sass" scoped>
    .lds-ripple
        display: inline-block
        position: relative
        height: 100%
        width: 100%
        max-width: 100px
        max-height: 100px
      
        div
            top: 0
            bottom: 0
            left: 0
            right: 0
            margin: auto
            position: absolute
            border: 4px solid #fff
            opacity: 1
            border-radius: 50%
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite

            &:nth-child(2)
                animation-delay: -0.5s

    @keyframes lds-ripple
        0%
            width: 0
            height: 0
            opacity: 1

        100%
            width: 100%
            height: 100%
            opacity: 0

    .btn-loader
        height: 39px
        width: 39px
        margin: 0 auto
</style>
