<template>
    <div v-if="notifications.length > 0" class="notifications"> 
        <TransitionGroup name="list" appear>
            <div
                v-for="notification in notifications"
                :key="notification.id"
                :class="[`type-${notification.type}`]"
                class="notification"
            >
                <div
                    v-if="notification.showClose"
                    @click="closeNotification(notification.id)"
                    class="closeNotification"
                >
                    <img src="@/assets/img/close.svg" alt="Закрыть">
                </div>
                <div class="notification-content">
                    {{ notification.message }}
                    <InviteFriend
                        v-if="showInvite(notification)"
                        :avatar="notification.options.avatar"
                        :name="notification.options.name"
                        :gameid="notification.options.gameId"
                        @close="closeNotification(notification.id)"
                    />
                </div>
            </div>
        </TransitionGroup>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { ActionsError } from '@/store/errors/actions';
import InviteFriend from './InviteFriend.vue';
import { TResponseNotification } from '@/store/errors/types';

@Options({
    components: {
        InviteFriend,
    },
})
export default class Notification extends Vue {
    get notifications() {
        return this.$store.state.errors.errors;
    }

    showInvite(error: TResponseNotification) {
        return error?.options?.invite;
    }

    closeNotification(idError: string) {
        this.$store.dispatch(ActionsError.removeNotifications, idError);
    }
}
</script>
<style lang="sass" src="@/styles/notification.sass"></style>
