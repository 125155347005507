<template>
  <div class="main-container" >
    <loader v-if="firstLoaded" />
    <router-view v-else v-slot="{ Component }">
      <Transition name="change-scene">
        <component :is="Component" />
      </Transition>
    </router-view>
    <Notification />
    
    <div class="gameVersion">{{ $store.state.version }}</div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Notification from '@/components/Notification.vue';
import { ActionCommonTypes } from '@/store/common/actions';
import loader from '@/components/loader.vue';
import { PROVIDERS, SDK_URL, TOKEN } from './constants';

import bridge from '@vkontakte/vk-bridge';
import { ActionsUser } from './store/user/actions';

@Options({
  components: {
    Notification,
    loader,
  },
})
export default class App extends Vue {
    get firstLoaded() {
        return !!localStorage.getItem(TOKEN) && !this.$store.state.user.isAuth;
    }

    closeModal() {
        this.$store.dispatch(ActionCommonTypes.showRules, false);
        this.$store.dispatch(ActionCommonTypes.setShowAddLife, false);
        this.$store.dispatch(ActionCommonTypes.showModal, false);
    }

    async initVk() {
        const { result } = await bridge.send('VKWebAppInit')
        if (!result) {
            return;
        }
        
        const { first_name, id, photo_200 } = await bridge.send('VKWebAppGetUserInfo');
        const provider = 'vk';
        await this.$store.dispatch(ActionsUser.authProvider, { provider, id, name: first_name, avatar: photo_200 });
        
        const authTokenData = await bridge.send('VKWebAppGetAuthToken', {
            'app_id': Number(process.env.VUE_APP_VK_APP_ID),
            'scope': 'friends',
        });
        
        let { response } = await bridge.send('VKWebAppCallAPIMethod', {
            'method': 'apps.get',
            'params': {
                v: '5.131',
                access_token: authTokenData.access_token,
                return_friends: 1,
                fields: 'photo_200'
            },
        });

        if (response?.profiles.length) {
            await this.$store.dispatch(ActionsUser.friendsScore, response.profiles);
        }
    }

    async initYa() {
        // @ts-ignore
        const ysdk = await YaGames.init();
        window.ysdk = ysdk;

        let player = await ysdk.getPlayer();
        const user = {
            provider: PROVIDERS.ya,
            id: player.getUniqueID(),
            name: player.getName(),
            avatar: player.getPhoto('large'),
        };
        await this.$store.dispatch(ActionsUser.authProvider, user);
    }

    async initCg() {
        const { CrazySDK } = window.CrazyGames;
        const crazyInstance = CrazySDK.getInstance();
        await crazyInstance.init();
        window.crazyInstance = crazyInstance;
        
        if (!localStorage.getItem(TOKEN)) {
            const user = {
                provider: PROVIDERS.cg,
                id: `${new Date().getTime()}_${Math.random().toFixed(5)}`,
                name: 'You',
                avatar: '',
            };
            await this.$store.dispatch(ActionsUser.authProvider, user);
        } else {
            await this.$store.dispatch(ActionsUser.userInfo);
        }

        window.addEventListener("wheel", (event) => event.preventDefault(), {
            passive: false,
        });

        window.addEventListener("keydown", (event) => {
            if (["ArrowUp", "ArrowDown", " "].includes(event.key)) {
                event.preventDefault();
            }
        });
    }

    async loadSDK(url: string) {
        await new Promise(resolve => {
            let script = document.createElement('script')
            script.src = url;
            script.addEventListener('load', resolve)
            document.head.appendChild(script)
        });
    }

    async mounted() {
        let provider = '';
        const url = new URLSearchParams(window.location.search);

        if (url.get('provider') === PROVIDERS.ya) {
            await this.loadSDK(SDK_URL.ya);
        }

        if (url.get('provider') === PROVIDERS.cg) {
            await this.loadSDK(SDK_URL.cg);
        }
  
        // @ts-ignore
        if (typeof YaGames !== 'undefined' && url.get('provider') === PROVIDERS.ya) {
            provider = PROVIDERS.ya;
        } else if (typeof window.CrazyGames !== 'undefined' && url.get('provider') === PROVIDERS.cg) {
            provider = PROVIDERS.cg;
        } else if (bridge && (bridge.isIframe() || bridge.isEmbedded())) {
            provider = PROVIDERS.vk;
        } 
        
        if (provider) {
            this.$store.dispatch(ActionCommonTypes.setProvider, provider);
            const method = `init${provider[0].toUpperCase() + provider.slice(1)}`;
            if (this.$options.methods[method]) {
                await this.$options.methods[method].apply(this);
            }
        }
    }
}
</script>
<style lang="sass" src="@/styles/base.sass"></style>
