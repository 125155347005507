
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BtnGame from '@/components/btnGame.vue';
import { INVITE_STATUS, PROVIDERS } from '@/constants';
import { ActionCommonTypes } from '@/store/common/actions';

@Options({
    components: {
        BtnGame,
    },
    sockets: {
        inviteCanceled() {
            this.inviteStatus = INVITE_STATUS.reject
        },
        inviteAccepted() {
            this.$emit('close');
        }
    },
})
export default class InviteFriend extends Vue {
    $socket: any;
    inviteStatus = INVITE_STATUS.pending;
    inviteStatuses = INVITE_STATUS;

    @Prop()
    name!: string;

    @Prop()
    avatar!: string;

    @Prop()
    gameid!: string;

    get isVk() {
        return this.$store.state.common.provider === PROVIDERS.vk;
    }

    acceptInvite() {
        if (!this.$store.state.game.currentGame) {
            if ((this.isVk && this.$store.state.user.user.lifes > 0) || (!this.isVk && this.$store.state.user.user.lifes === 3)) {
                this.$socket.emit('joinGame', { gameId: this.gameid, user: this.$store.state.user.user });
            } else {
                this.$store.dispatch(ActionCommonTypes.showModal, true);
            }
        } else {
            this.$store.dispatch(ActionCommonTypes.setShowExitGame, true);
            this.$store.dispatch(ActionCommonTypes.setChangeGame, this.gameid);
        }
    }

    rejectInvite() {
        this.$socket.emit('rejectInvite', { gameId: this.gameid });
        this.$emit('close');
    }
}
