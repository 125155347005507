import { MutationTree } from 'vuex';
import { TCommonState } from './types';
import { TMode } from '@/model/Common';

export enum MutationType {
    setScene = 'SET_STAGE',
    setModeList = 'SET_MODE_LIST',
    setMode = 'SET_MODE',
    setLoading = 'SET_LOADING',
    setShowModal = 'SET_SHOW_MODAL',
    setShowRules = 'SET_SHOW_RULES',
    setSound = 'SET_SOUND',
    setShowAddLife = 'SET_SHOW_ADD_LIFE',
    setShowExitGame = 'SET_SHOW_EXIT_GAME',
    setChangeGame = 'SET_CHANGE_GAME',
    setProvider = 'SET_PROVIDER',
}

export type Mutations = {
    [MutationType.setScene](state: TCommonState, scene: string): void,
    [MutationType.setModeList](state: TCommonState, modeList: Array<TMode>): void,
    [MutationType.setMode](state: TCommonState, mode: number): void,
    [MutationType.setLoading](state: TCommonState, loading: boolean): void,
    [MutationType.setShowModal](state: TCommonState, show: boolean): void,
    [MutationType.setShowRules](state: TCommonState, show: boolean): void,
    [MutationType.setSound](state: TCommonState, sound: boolean): void,
    [MutationType.setShowAddLife](state: TCommonState, show: boolean): void,
    [MutationType.setShowExitGame](state: TCommonState, show: boolean): void,
    [MutationType.setChangeGame](state: TCommonState, change: string): void,
    [MutationType.setProvider](state: TCommonState, provider: string): void,
}

export const mutations: MutationTree<TCommonState> & Mutations= {
    [MutationType.setScene](state, scene) {
        state.currentScene = scene;
    },
    [MutationType.setModeList](state, modeList) {
        state.gameModeList = modeList;
    },
    [MutationType.setMode](state, mode) {
        state.selectedMode = mode;
    },
    [MutationType.setLoading](state, loading) {
        state.isLoading = loading;
    },
    [MutationType.setShowModal](state, show) {
        state.showModalGame = show;
    },
    [MutationType.setShowRules](state, show) {
        state.showRules = show;
    },
    [MutationType.setSound](state, sound) {
        state.soundOn = sound;
    },
    [MutationType.setShowAddLife](state, show) {
        state.showModalAddLife = show;
    },
    [MutationType.setShowExitGame](state, show) {
        state.showModalExitGame = show;
    },
    [MutationType.setChangeGame](state, change) {
        state.changeGame = change;
    },
    [MutationType.setProvider](state, provider) {
        state.provider = provider;
    },
}
