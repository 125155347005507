import { ActionTree } from 'vuex'
import { RootState } from "../types";
import { TCommonState } from "./types";
import { MutationType } from './mutations';

export enum ActionCommonTypes {
    setScene = 'SET_SCENE',
    actionTypes = 'SOCKET_setMode',
    setMode = 'SET_MODE',
    setLoading = 'SET_LOADING',
    showModal = 'SHOW_MODAL',
    showRules = 'SHOW_RULES',
    setSound = 'SET_SOUND',
    setShowAddLife = 'SET_SHOW_ADD_LIFE',
    setShowExitGame = 'SET_SHOW_EXIT_GAME',
    setChangeGame = 'SET_CHANGE_GAME',
    setProvider = 'SET_PROVIDER',
}

export const actions: ActionTree<TCommonState, RootState> = {
    [ActionCommonTypes.setScene]({ commit }, scene: string) {
        commit(MutationType.setScene, scene);
    },
    [ActionCommonTypes.actionTypes]({ commit }, { modeList }) {
        commit(MutationType.setModeList, modeList);
    },
    [ActionCommonTypes.setMode]({ commit }, mode: number) {
        commit(MutationType.setMode, mode);
    },
    [ActionCommonTypes.setLoading]({ commit }, loading: boolean) {
        commit(MutationType.setLoading, loading);
    },
    [ActionCommonTypes.showModal]({ commit }, show: boolean) {
        commit(MutationType.setShowModal, show);
    },
    [ActionCommonTypes.showRules]({ commit }, show: boolean) {
        commit(MutationType.setShowRules, show);
    },
    [ActionCommonTypes.setSound]({ commit, state }) {
        commit(MutationType.setSound, !state.soundOn);
    },
    [ActionCommonTypes.setShowAddLife]({ commit }, show: boolean) {
        commit(MutationType.setShowAddLife, show);
    },
    [ActionCommonTypes.setShowExitGame]({ commit }, show: boolean) {
        commit(MutationType.setShowExitGame, show);
    },
    [ActionCommonTypes.setChangeGame]({ commit }, change: string) {
        commit(MutationType.setChangeGame, change);
    },
    [ActionCommonTypes.setProvider]({ commit }, provider: string) {
        commit(MutationType.setProvider, provider);
    },
} 
