import { Module } from 'vuex';
import { RootState } from '../types';
import { TErrorState } from './types';
import { mutations } from './mutations';
import { actions } from './actions'; 

export const state: TErrorState = {
    errors: [],
}

export const errors: Module<TErrorState, RootState> = {
    state,
    mutations,
    actions,
}
